.container {
  height: var(--cross-esterman-height);
  width: var(--cross-esterman-width);
  position: relative;
}

.vl {
  position: absolute;
  border-right: var(--cross-esterman-border-width) solid black;
  height: var(--cross-esterman-height);
  margin-left: var(--half-width);
}

.hl {
  position: absolute;
  border-bottom: var(--cross-esterman-border-width) solid black;
  width: var(--cross-esterman-width);
  margin-top: var(--half-height);
}
