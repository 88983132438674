.container {
  height: 252px;
  width: 252px;
  position: relative;
}

.vl {
  position: absolute;
  border-right: 2px solid black;
  height: 252px;
  margin-left: 125px;
}

.hl {
  position: absolute;
  border-bottom: 2px solid black;
  width: 252px;
  margin-top: 125px;
}

.noDataText {
  margin-top: 102px !important;
}

.imageRender {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
