.container {
  height: 312px;
  width: 312px;
  position: relative;
}

.vl {
  position: absolute;
  border-right: 2px solid black;
  height: 312px;
  margin-left: 155px;
}

.hl {
  position: absolute;
  border-bottom: 2px solid black;
  width: 312px;
  margin-top: 155px;
}
