@font-face {
  font-family: "EyeChart";
  src:
    url("/src/fonts/Eyechart.woff2") format("woff2"),
    url("/src/fonts/Eyechart.woff") format("woff"),
    url("/src//fonts//Eyechart.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Snellen";
  src:
    url("/src/fonts/Eyechart.woff2") format("woff2"),
    url("/src/fonts/Eyechart.woff") format("woff"),
    url("/src/fonts/Snellen.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.eye_chart_font {
  font-family: "EyeChart" !important;
}

.snellen_chart_font {
  font-family: "Snellen" !important;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  max-width: 400px;
  margin: 1rem auto;
  align-items: "center";
  text-align: center; /* Center the grid items within each column */
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

/* Add a gap after the third column */
.gridItem:nth-child(3n) {
  margin-right: 60px; /* Adjust the gap size as needed */
  margin-left: 10px;
}

@media (max-width: 640px) {
  .gridContainer {
    grid-gap: 5px;
    max-width: 300px;
  }
  .gridItem {
    font-size: 12px;
  }
  .gridItem:nth-child(3n) {
    margin-right: 30px; /* Adjust the gap size as needed */
    margin-left: 5px;
  }
}
