.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  max-width: 400px;
  margin: 1rem auto;
  text-align: center; /* Center the grid items within each column */
}

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

/* Add a gap after the third column */
.gridItem:nth-child(3n) {
  margin-right: 60px; /* Adjust the gap size as needed */
  margin-left: 10px;
}

@media (max-width: 640px) {
  .gridContainer {
    grid-gap: 5px;
    max-width: 250px;
  }
  .gridItem {
    font-size: 12px;
  }
  .gridItem:nth-child(3n) {
    margin-right: 30px; /* Adjust the gap size as needed */
    margin-left: 5px;
  }
}
