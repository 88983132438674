/* Search results container to mimic React docs */
.searchResultsContainer {
  position: absolute;
  top: 50px;
  width: 100%;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-height: 600px; /* Limit height */
  overflow-y: auto; /* Allow scrolling for large results */
}

.searchContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.searchBoxContainer {
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.searchBoxContainer:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.searchInput {
  background: #fff;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  color: #333;
  width: 100%;
  transition: all 0.3s ease;
}

.searchInput:focus {
  border: 1px solid #e2772e;
  background: #ffffff;
}

.searchResultsContainer {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 2px 2px;
  overflow: auto;
  transform: translateY(-10px);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.searchResultsContainer.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Default state (hidden) */
.hidden {
  display: none;
}

/* When focused, make the results visible */
.visible {
  display: block;
}

/* Styling for individual hits */
.hitItem {
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  padding: 12px;
}

.hitItem__list {
  list-style: none;
}
.hitItem:last-child {
  border-bottom: none;
}

.hitItem:hover {
  background-color: #f9f9f9;
}
ol li {
  margin-top: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .searchContainer {
    max-width: 100%;
  }

  .searchResultsContainer {
    max-width: 100%;
  }
}
