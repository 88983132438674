.ais-Hits-item {
  list-style: none !important;
  padding: 0;
}
.ais-Hits-list {
  padding: 0;
}

.ais-Highlight-highlighted {
  background-color: rgba(226, 119, 46, 0.5);
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none; /* Hide the submit and reset buttons */
}
