.triangleUp {
  position: absolute;
  margin-top: 28px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 9px solid black;
}

.innerTriangle {
  position: relative;
  top: 3.5px;
  left: -1.9px;
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 3px solid #eef0fa;
}
