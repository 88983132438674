.container {
  height: var(--new-pattern-cross-length);
  width: var(--new-pattern-cross-length);
  position: relative;
}

.topLeftSection {
  width: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  height: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  margin-left: 0px;

  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  padding-right: 4px;
  padding-bottom: 4px;
}

.topRightSection {
  width: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  height: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  margin-left: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2 +
      var(--cross-border-width)
  );

  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  padding-left: 4px;
  padding-bottom: 4px;
}

.bottomLeftSection {
  width: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  height: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  margin-top: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2 +
      var(--cross-border-width)
  );

  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  padding-right: 4px;
  padding-top: 4px;
}

.bottomRightSection {
  width: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  height: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2
  );
  margin-top: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2 +
      var(--cross-border-width)
  );
  margin-left: calc(
    (var(--new-pattern-cross-length) - var(--cross-border-width)) / 2 +
      var(--cross-border-width)
  );

  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 4px;
  padding-top: 4px;
}
