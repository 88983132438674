.external-details-dialog {
  .haederSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    svg {
      cursor: pointer;
      color: rgba(255, 0, 0, 0.743);
    }
  }
  .examDetailsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .examDetails__content {
      width: 45%;
      margin-bottom: 25px;
      strong {
        margin: 0;
        padding: 0;

        font-size: 15px;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}
