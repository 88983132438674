.popup_menu {
  position: absolute;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 100;
  top: 85px;
  right: 0;
  /* top: 95px;
  right: 10px; */
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}
/* Show the menu */
.show_menu {
  opacity: 1;
  visibility: visible;
}

.menu_item {
  display: block;
  padding: 5px 10px;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.menu_item:hover {
  background-color: #f0f0f0;
}

/* Medium screens */
@media (max-width: 755px) {
  .popup_menu {
    top: 45px;
  }
}
@media (max-width: 537px) {
  .popup_menu {
    top: 49px;
  }
}
